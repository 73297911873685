<script setup lang="ts">
import { NavigationMenuList, type NavigationMenuListProps } from "radix-vue";
import { cn, type ClassValue } from "@/utils";

defineProps<
	NavigationMenuListProps & {
		class?: ClassValue;
	}
>();
</script>

<template>
	<NavigationMenuList
		v-bind="{
			...$props,
			class: cn(
				'flex flex-1 list-none items-center justify-center gap-x-1',
				$props.class,
			),
		}"
	>
		<slot />
	</NavigationMenuList>
</template>
