<script setup lang="ts">
import {
	NavigationMenuContent,
	useEmitAsProps,
	type NavigationMenuContentProps,
	type NavigationMenuContentEmits,
} from "radix-vue";
import { cn, type ClassValue } from "@/utils";
import { computed } from "vue";

const props = defineProps<
	NavigationMenuContentProps & {
		class?: ClassValue;
	}
>();
const emit = defineEmits<NavigationMenuContentEmits>();
const emitsAsProps = useEmitAsProps(emit);

const classes = computed(() => {
	return cn(
		"left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto",
		props.class,
	);
});
</script>

<template>
	<NavigationMenuContent
		v-bind="{ ...$props, ...emitsAsProps, class: classes }"
	>
		<slot />
	</NavigationMenuContent>
</template>
