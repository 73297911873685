import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M14.8 3.5c-4 0-7.2 3.2-7.2 7.1 0 4 3.3 7.1 7.2 7.1 4 0 7.2-3.1 7.2-7 0-4-3.2-7.2-7.2-7.2ZM2 22.5v-19h3.5v19H2Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }