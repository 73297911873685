import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M19.6 4.4A17.6 17.6 0 0 0 15.1 3l-.6 1.2c-1.7-.3-3.3-.3-5 0A10.7 10.7 0 0 0 8.8 3c-1.5.3-3 .8-4.4 1.4A19 19 0 0 0 1 17.2 18.1 18.1 0 0 0 6.7 20L7.8 18 6 17.1v-.1l.4-.3a12.8 12.8 0 0 0 11.2 0l.3.3v.1l-1.7.8v.1l1 1.9h.2a18 18 0 0 0 5.5-2.9c.5-4.8-.8-9-3.2-12.7ZM8.4 14.6c-1.1 0-2-1-2-2.3 0-1.2.9-2.2 2-2.2 1 0 2 1 2 2.2 0 1.3-1 2.3-2 2.3Zm7.3 0c-1.1 0-2-1-2-2.3 0-1.2.9-2.2 2-2.2 1 0 2 1 2 2.2 0 1.3-1 2.3-2 2.3Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }