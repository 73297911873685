import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M12 0c6.6 0 12 5.5 12 12.3 0 5.4-3.4 10-8.2 11.7-.6.1-.8-.3-.8-.6V20c0-1.1-.4-1.9-.8-2.3 2.6-.3 5.5-1.3 5.5-6 0-1.4-.5-2.5-1.3-3.3.1-.4.6-1.6-.1-3.3 0 0-1-.3-3.3 1.3a11.3 11.3 0 0 0-6 0C6.7 4.8 5.7 5 5.7 5c-.7 1.7-.2 3-.1 3.3-.8.8-1.3 2-1.3 3.3 0 4.7 2.8 5.7 5.5 6-.3.3-.6.9-.8 1.7-.6.3-2.4.8-3.4-1 0 0-.7-1.2-1.9-1.3 0 0-1.2 0 0 .7 0 0 .7.4 1.3 1.8 0 0 .7 2.2 4 1.5v2.3c0 .3-.2.7-.8.6A12.3 12.3 0 0 1 0 12.3C0 5.5 5.4 0 12 0Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }