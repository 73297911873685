<script setup lang="ts">
import {
	NavigationMenuViewport,
	type NavigationMenuViewportProps,
} from "radix-vue";
import { cn, type ClassValue } from "@/utils";

defineProps<
	NavigationMenuViewportProps & {
		class?: ClassValue;
	}
>();
</script>

<template>
	<div
		class="absolute -left-1/2 -right-1/2 top-full mx-auto flex justify-center"
	>
		<NavigationMenuViewport
			v-bind="{
				...$props,
				class: cn(
					'origin-top-center relative bg-stone-800 mt-2 h-[var(--radix-navigation-menu-viewport-height)] overflow-hidden rounded-md border border-white/10 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top-2 data-[state=closed]:fade-out-0 data-[state=open]:slide-in-from-top-2 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=open]:fade-in-0',
					$props.class,
				),
			}"
		/>
	</div>
</template>
