import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M14 10.2 22.7 0h-2L13 8.8 7 0H0l9.2 13.3L0 24h2l8-9.3 6.5 9.3h7L14 10.2ZM2.8 1.6H6l14.6 20.9h-3.2L2.8 1.5Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }