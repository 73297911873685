<script setup lang="ts">
import {
	NavigationMenuRoot,
	type NavigationMenuRootEmits,
	type NavigationMenuRootProps,
} from "radix-vue";
import { cn, type ClassValue } from "@/utils";
import { computed } from "vue";

const props = defineProps<
	NavigationMenuRootProps & {
		class?: ClassValue;
	}
>();
defineEmits<NavigationMenuRootEmits>();

const classes = computed(() => {
	return cn(
		"relative z-20 flex max-w-max flex-1 items-center justify-center",
		props.class,
	);
});
</script>

<template>
	<NavigationMenuRoot
		v-bind="{ ...props, class: classes }"
		@update:model-value="$emit('update:modelValue', $event)"
	>
		<slot />
	</NavigationMenuRoot>
</template>
