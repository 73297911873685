import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  "vector-effect": "non-scaling-stroke"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      x: "9.25",
      y: "8.25",
      width: "9",
      height: "9",
      rx: "1",
      stroke: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M1.75 11.75c0 3.41 1 6.5 2 6.5s2-3.84 2-6.5c0-2.5-1-4-2-4s-2 1.5-2 4Zm3.75-6.5a1.8 1.8 0 0 1-1.75 2c-.99.03-2-1-2-2 0-2 1.67-3.17 2.5-3.5 0 1.8 1.18 2.57 1.25 3.5Z",
      stroke: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M7.25 3.88c.88-.75 2-1.13 3-1.13 2 0 4.5 1.5 4.5 4.5s-2.5 4.5-4.5 4.5c-.85 0-1.78-.27-2.58-.8",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }