<script lang="ts">
import DiscordIcon from "@/icons/discord.svg?component";
import PatreonIcon from "@/icons/patreon.svg?component";
import GithubIcon from "@/icons/github.svg?component";
import TwitchIcon from "@/icons/twitch.svg?component";
import XIcon from "@/icons/x.svg?component";
import YoutubeIcon from "@/icons/youtube.svg?component";
import ArrowUpIcon from "@/icons/arrow-up.svg?component";
import { type SocialLinkName, socialLinks, type NavLinkName } from "./links";
import ServerManagerIcon from "@/icons/server-manager.svg?component";
import DatabaseIcon from "@/icons/database.svg?component";
import BrandingIcon from "@/icons/branding.svg?component";
import CodeFile from "@/icons/code-file.svg?component";
import EmailIcon from "@/icons/email.svg?component";

const headerLinkNames = [
	"downloads",
	"server_list",
	"docs",
] as const satisfies NavLinkName[];
export type HeaderLinkName = (typeof headerLinkNames)[number];

const navMenuLinkNames = [
	"server_manager",
	"natives",
	"resource_hub",
	"branding",
] as const satisfies NavLinkName[];
export type DesktopNavMenuLink = (typeof navMenuLinkNames)[number];

const navMenuLinkIcons: Record<DesktopNavMenuLink, Component> = {
	branding: BrandingIcon,
	natives: DatabaseIcon,
	server_manager: ServerManagerIcon,
	resource_hub: CodeFile,
};

const socialLinkIcons: Record<SocialLinkName, Component> = {
	discord: DiscordIcon,
	patreon: PatreonIcon,
	github: GithubIcon,
	twitch: TwitchIcon,
	x: XIcon,
	youtube: YoutubeIcon,
	email: EmailIcon,
};
</script>

<script setup lang="ts">
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuList,
	NavigationMenuTrigger,
	NavigationMenuLink,
	NavigationMenuViewport,
} from "@/components/ui/navigation-menu";
import { ref, type Component, watch } from "vue";
import {
	DialogRoot,
	DialogContent,
	DialogPortal,
	DialogTrigger,
	DialogTitle,
	DialogOverlay,
	VisuallyHidden,
} from "radix-vue";
import { useMediaQuery } from "@vueuse/core";
import BurgerToggle from "./BurgerToggle.vue";
import { getCurrentLocale, loadI18n } from "@/i18n/i18n.client";

defineProps<{
	localizedNavLinks: Record<NavLinkName, { href: string; external: boolean }>;
}>();

const { t } = await loadI18n(getCurrentLocale());

const isMobileMenuOpen = ref(false);

const isDesktop = useMediaQuery("(min-width: 768px)");

// Hide mobile menu overlay when resizing to desktop size window
watch(isDesktop, () => {
	if (isMobileMenuOpen.value && isDesktop.value) {
		isMobileMenuOpen.value = false;
	}
});
</script>

<template>
	<DialogRoot v-model:open="isMobileMenuOpen">
		<DialogTrigger as-child>
			<BurgerToggle
				v-model="isMobileMenuOpen"
				class="p-1.5 text-stone-200 hover:text-white md:hidden"
			/>
		</DialogTrigger>
		<DialogPortal>
			<DialogOverlay />
			<DialogContent
				:aria-describedby="undefined"
				@interact-outside.prevent
				@focus-outside.prevent
				@pointer-down-outside.prevent
				class="fixed inset-y-0 left-0 z-10 h-dvh w-screen gap-4 bg-stone-900/90 px-6 pt-[--header-height] backdrop-blur ease-out data-[state=closed]:duration-300 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:slide-out-to-bottom-2 data-[state=open]:slide-in-from-bottom-2 md:px-10"
			>
				<VisuallyHidden>
					<DialogTitle>{{ t("labels.main_menu") }}</DialogTitle>
				</VisuallyHidden>
				<nav class="container flex h-full flex-col justify-between">
					<ul class="flex flex-col">
						<li
							v-for="({ href, external }, name) of localizedNavLinks"
							class="inline-flex"
						>
							<a
								class="inline-flex w-full border-b border-white/10 py-3 font-medium transition-colors hover:text-stone-200"
								:target="external ? '_blank' : undefined"
								:href="href"
							>
								{{ t(`nav_links.${name}.label`) }}
							</a>
						</li>
					</ul>
					<ul class="flex flex-wrap justify-center gap-1 py-6">
						<li v-for="(href, name) in socialLinks">
							<a
								:href="href"
								:aria-label="t(`social_link_labels.${name}`)"
								target="_blank"
								class="group inline-flex p-2"
							>
								<component
									:is="socialLinkIcons[name]"
									class="size-6 text-white/60 transition-colors group-hover:text-white"
								/>
							</a>
						</li>
					</ul>
				</nav>
			</DialogContent>
		</DialogPortal>
	</DialogRoot>
	<NavigationMenu
		class="flex-shrink-1 hidden md:flex"
		:aria-label="t('labels.main_menu')"
	>
		<NavigationMenuViewport class="w-[694px] rounded-xl" />
		<NavigationMenuList class="gap-0 px-4 lg:px-8">
			<NavigationMenuItem v-for="name of headerLinkNames" :key="name">
				<NavigationMenuLink
					:href="localizedNavLinks[name].href"
					:target="localizedNavLinks[name].external ? '_blank' : undefined"
					class="flex h-10 items-center truncate rounded-md px-3.5 text-stone-200 outline-none transition-colors hover:text-white focus-visible:bg-white/10 focus-visible:text-white"
				>
					{{ t(`nav_links.${name}.label`) }}
				</NavigationMenuLink>
			</NavigationMenuItem>
			<NavigationMenuItem
				class="relative inline-flex h-10 items-center overflow-hidden text-stone-200 transition-colors hover:text-white"
			>
				<NavigationMenuTrigger class="px-3.5 text-base font-normal">
					{{ t("nav_links.other") }}
				</NavigationMenuTrigger>
				<NavigationMenuContent>
					<ul class="grid w-full grid-cols-2 gap-1 p-2.5">
						<NavigationMenuItem v-for="name of navMenuLinkNames" :key="name">
							<NavigationMenuLink
								:href="localizedNavLinks[name].href"
								:target="
									localizedNavLinks[name].external ? '_blank' : undefined
								"
								class="group relative flex items-start gap-2.5 rounded-md py-2.5 pl-2.5 pr-5 outline-green-200 hover:bg-stone-600"
							>
								<span class="rounded-md bg-green-400/25 p-1">
									<component
										:is="navMenuLinkIcons[name]"
										class="size-5 shrink-0 stroke-[1.7] text-green-400"
									/>
								</span>
								<div class="flex flex-col gap-y-px">
									<span class="mb-0.5 text-base font-medium">
										{{ t(`nav_links.${name}.label`) }}
									</span>
									<span
										class="line-clamp-2 text-ellipsis text-sm leading-normal text-stone-200"
									>
										{{ t(`nav_links.${name}.description`) }}
									</span>
								</div>
								<ArrowUpIcon
									aria-hidden="true"
									class="absolute right-4 top-3 size-5 rotate-90 stroke-[1.5] text-stone-200 opacity-0 transition-[opacity,transform] duration-300 group-hover:translate-x-1 group-hover:opacity-100"
								/>
							</NavigationMenuLink>
						</NavigationMenuItem>
					</ul>
				</NavigationMenuContent>
			</NavigationMenuItem>
		</NavigationMenuList>
	</NavigationMenu>
</template>
