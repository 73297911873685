import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "m26.7 15-4.3 4.2h-4.3L14.4 23v-3.8H9.6v-16h17.1v11.7ZM8.5 1 3.1 6.4v19.2h6.5V31l5.3-5.4h4.3L29 16V1H8.5Zm12.9 5.9h2.1v6.4h-2.1V7Zm-6 0h2.2v6.4h-2.1V7Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }