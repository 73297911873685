import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M13.11 2H7.78c-1.25 0-1.87 0-2.34.24a2.3 2.3 0 0 0-.98.97c-.24.48-.24 1.1-.24 2.35v12.88c0 1.25 0 1.87.24 2.35.22.41.56.75.98.97.47.24 1.1.24 2.33.24h8.46c1.24 0 1.86 0 2.33-.24.42-.22.76-.56.98-.97.24-.48.24-1.1.24-2.34V8.67M13.1 2a2.22 2.22 0 0 1 1.35.33c.23.14.42.33.8.71l3.48 3.47c.38.39.57.58.71.8a2.22 2.22 0 0 1 .33 1.36M13.1 2v3.11c0 1.25 0 1.87.24 2.34.22.42.56.76.97.97.48.25 1.1.25 2.34.25h3.12m0 0h0m-5.56 4.44 2.22 2.22-2.22 2.23m-4.44 0-2.22-2.23 2.22-2.22",
      stroke: "currentColor",
      "vector-effect": "non-scaling-stroke",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }