export const navLinks = {
	downloads: "/downloads",
	server_list: "/servers",
	docs: "https://docs.altv.mp/",
	server_manager: "/server-manager",
	natives: "https://natives.altv.mp/",
	resource_hub: "/resources",
	troubleshoot: "https://docs.altv.mp/articles/troubleshooting/client.html",
	branding: "/branding",
};
export type NavLinkName = keyof typeof navLinks;

export const isTranslatableLink = (link: string) => {
	return !link.startsWith("http");
};

export const socialLinks = {
	discord: "https://discord.com/invite/yQvKgSs",
	github: "https://github.com/altmp",
	patreon: "https://patreon.com/altVMP",
	twitch: "https://twitch.tv/altvmultiplayer",
	x: "https://twitter.com/altVMP",
	youtube: "https://youtube.com/@altvmultiplayer",
	email: "mailto:contact@alt-mp.com",
};
export type SocialLinkName = keyof typeof socialLinks;

export const primarySocialLinkNames = ["discord", "patreon", "github"] as const;
export type PrimarySocialLinkName = (typeof primarySocialLinkNames)[number];

export const primarySocialLinks = Object.fromEntries(
	primarySocialLinkNames.map((linkName) => [linkName, socialLinks[linkName]]),
) as Record<PrimarySocialLinkName, string>;
